import type { RequestOverrides } from '@wix/http-client';
import { type IHttpClient } from '@wix/yoshi-flow-editor';
import {
  getPostPage,
  getPostPageMetadata,
  getPostPagePreview,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import { type Post } from '@wix/ambassador-blog-v3-post/types';
import {
  createAction,
  EXPERIMENTS,
  fetchTagsSuccess,
} from '@wix/communities-blog-client-common';
import { WarmupDataHandler } from '@app/common/warmup-data-handler';
import { type PostPageThunkAction } from '@app/external/post-page/types';
import { type PlatformApi } from '../controller/platform-api';
import { getWarmupKeyForOldPostPage } from '../helpers/get-warmup-data-key-for-old-post-page';
import { getLanguageCode } from '../selectors/locale-selectors';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';
import { normalizePost, normalizePostV3 } from '../services/post-utils';
import { getCategoryIds } from '../store/categories/categories-selectors';
import type { GetState, NormalizedPost } from '../types';
import type { FlowAPI } from '../types/platform-types';
import { fetchPostMetadataSuccess } from './fetch-post-metadata';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

interface PreviewPostParams {
  includeDraft?: boolean;
  instance?: string;
}

interface MakeRequestParams {
  includeDraft?: boolean;
  instance?: string;
  httpClient: IHttpClient;
  languageCode: string;
  dispatch: Function;
  getState: GetState;
  platformApi: PlatformApi;
  flowAPI: FlowAPI;
}

export const fetchPostRequest = createAction(
  FETCH_POST_REQUEST,
  (payload: { postSlug: string }) => payload,
);
export const fetchPostSuccess = createAction(
  FETCH_POST_SUCCESS,
  (payload: { post: NormalizedPost; postSlug: string }) => payload,
);
export const fetchPostFailure = createAction(
  FETCH_POST_FAILURE,
  (payload: { postSlug: string; error: unknown }) => payload,
);

export class FetchPostError extends Error {
  readonly name = 'FetchPostError';
  readonly status = 404;
}

const makeRequestToPlatformizedApi = async (
  postSlugOrId: string,
  {
    includeDraft,
    instance,
    httpClient,
    languageCode,
    dispatch,
    platformApi,
    flowAPI,
  }: MakeRequestParams,
) => {
  const platformizedParams = {
    translationsName: 'main',
    languageCode,
  };

  const request = includeDraft
    ? getPostPagePreview({
        draftPostId: postSlugOrId,
        ...platformizedParams,
      })
    : getPostPage({
        postId: postSlugOrId,
        ...platformizedParams,
      });

  try {
    const initialData = await new WarmupDataHandler({
      isSSR: flowAPI.environment.isSSR,
      isWarmupDataExperimentEnabled:
        !includeDraft &&
        flowAPI.essentials.experiments.enabled(
          EXPERIMENTS.USE_WARMUP_STATE_IN_OLD_POST_PAGE,
        ),
      loaderFn: async () => {
        const requestOverrides: RequestOverrides | undefined = instance
          ? {
              signedInstance: instance,
            }
          : undefined;
        const [postPageResponse, postPageMetadataResponse] = await Promise.all([
          httpClient.request(request, requestOverrides),
          !includeDraft
            ? httpClient.request(
                getPostPageMetadata({
                  postId: postSlugOrId,
                  languageCode,
                  skipViewCountIncrement: true,
                }),
                requestOverrides,
              )
            : null,
        ]);

        return { postPageResponse, postPageMetadataResponse };
      },
      platformApi,
      warmupDataKey: getWarmupKeyForOldPostPage(postSlugOrId),
    }).load();

    const postPage = initialData?.postPageResponse.data?.postPage;
    const post = postPage?.post;

    if (!post || !postPage || typeof post.slug === 'undefined') {
      throw new FetchPostError('Post not found');
    }

    dispatch(fetchTagsSuccess(postPage.tags));

    return {
      ...normalizePostV3(post),
      tags: postPage.tags ?? [],
      likeCount:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.likeCount ??
        post?.metrics?.likes ??
        0,
      totalComments:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.totalComments ??
        post.metrics?.comments ??
        0,
      averageRating:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.averageRating ??
        post.metrics?.averageRating ??
        0,
      totalRatings:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.totalRatings ??
        post.metrics?.totalRatings ??
        0,
      viewCount:
        initialData.postPageMetadataResponse?.data?.postPageMetadata
          ?.postMetadata?.viewCount ??
        post.metrics?.views ??
        0,
      /*
        To maintain compatibility with the old post page, we need to return the slug in the slugs array.
        It is used in the post page to determine if the post is avialable by slug or not.
      */
      slugs: [post.slug, postSlugOrId],
    };
  } catch (error: any) {
    if (error?.response?.status) {
      platformApi.seo.setSeoStatusCode(error.response.status);
    } else {
      platformApi.seo.setSeoStatusCode(500);
    }

    throw error;
  }
};

export const fetchPost = (
  postSlug: string,
  { includeDraft, instance }: PreviewPostParams = {},
): PostPageThunkAction<NormalizedPost> => {
  return (dispatch, getState, extraArgs) => {
    const { httpClient, platformApi, flowAPI } = extraArgs;
    dispatch(fetchPostRequest({ postSlug }));
    const state = getState();

    const promise = makeRequestToPlatformizedApi(postSlug, {
      httpClient,
      languageCode: getLanguageCode(state),
      includeDraft,
      instance,
      dispatch,
      getState,
      platformApi,
      flowAPI,
    });

    return completeFetchPost(postSlug, promise)(dispatch, getState, extraArgs);
  };
};

export const preFetchPost =
  (
    postSlug: string,
    { includeDraft, instance }: PreviewPostParams = {},
  ): PostPageThunkAction<NormalizedPost> =>
  (dispatch, getState, { httpClient, platformApi, flowAPI }) => {
    dispatch(fetchPostRequest({ postSlug }));

    const state = getState();
    const languageCode = getLanguageCode(state);

    return makeRequestToPlatformizedApi(postSlug, {
      languageCode,
      includeDraft,
      instance,
      httpClient,
      dispatch,
      getState,
      platformApi,
      flowAPI,
    });
  };

export const completeFetchPost =
  (
    postSlug: string,
    preFetchResult: ReturnType<typeof makeRequestToPlatformizedApi>,
  ): PostPageThunkAction<NormalizedPost> =>
  async (
    dispatch,
    getState,
    { platformApi, httpClient, aggregatorRequest },
  ) => {
    try {
      let post: NormalizedPost | Post;
      let capturedError;

      try {
        post = await preFetchResult;
      } catch (e) {
        capturedError =
          e && typeof e === 'object' && 'response' in e ? e.response : e;

        if (
          capturedError &&
          typeof capturedError === 'object' &&
          'status' in capturedError &&
          capturedError.status === 404 &&
          getEnvironment(platformApi).isEditorSegment
        ) {
          const resp = await getDemoPosts({
            httpClient,
            getState,
            dispatch,
            platformApi,
            aggregatorRequest,
            query: { slugs: [postSlug] },
          });
          post = resp?.posts?.[0];
          if (post) {
            capturedError = null;
          }
        }
      }

      if (capturedError) {
        throw capturedError;
      }

      const normalizedPost = normalizePost({
        state: getState(),
        // @ts-expect-error
        post,
        blogCategoryIds: getCategoryIds(getState()),
      });

      dispatch(fetchPostSuccess({ post: normalizedPost, postSlug }));
      // @ts-expect-error
      dispatch(fetchPostMetadataSuccess(post));

      return normalizedPost;
    } catch (error) {
      dispatch(fetchPostFailure({ postSlug, error }));
      throw error;
    }
  };
